.root {
	display: flex;
	flex-direction: column;
	padding: var(--hdsplus-spacing-07);
}

.image {
	margin-bottom: var(--hdsplus-spacing-05);
	max-width: 48px;

	& img {
		max-width: 100%;
		height: auto;
	}
}

.headingWrapper {
	margin-bottom: var(--hdsplus-spacing-05);
}

.heading {
	display: block;
	font-weight: 600;
}

.badge {
	margin-top: var(--hdsplus-spacing-04);
}

.description {
	margin-bottom: var(--hdsplus-spacing-07);
}

.icon {
	display: flex;
	align-items: center;
	height: 100%;
}